import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import appStore from './stores/index';
import authStore from './stores/auth';

const stores = {
  appStore,
  authStore,
};

const ua = navigator.wxuserAgent || navigator.userAgent;

window.ISWEIXIN = ua.match(/MicroMessenger\/((\d+)\.(\d+))\.(\d+)/i) || ua.match(/MicroMessenger\/((\d+)\.(\d+))/i);
window.ISIOS = navigator.userAgent.match(/(iPad|iPod|iPhone).*OS\s([\d_]+)/);
window.ISLOCAL = Boolean(window.location.hostname === 'localhost'
    || window.location.hostname === '[::1]'
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

ReactDOM.render(<Provider {...stores}><Router><App /></Router></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
