import { observable, action, reaction } from 'mobx';
import { prefix, openPages } from '../config';
import api from '../services/api';
import appStore from './index';

const key = `${prefix}accessToken`;

class AuthStore {
  @observable token = window.localStorage.getItem(key);

  @observable wechat

  @observable customer

  @observable isAuthenticating = true;

  @observable isAuthenticated = false;

  constructor() {
    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem(key, token);
        } else {
          window.localStorage.removeItem(key);
        }
      }
    );
  }

  @action setToken(token) {
    this.token = token;
  }

  @action setWechat(wx) {
    this.wechat = wx;
  }

  @action async getMe(token) {
    if (token) this.setToken(token.accessToken);

    if (this.customer && this.customer.id) return this.customer;

    try {
      const customer = await api.customer.get({ id: 'me' });

      if (customer) {
        this.setUser(customer);
        this.setAuthenticated(true);
      }

      return customer;
    } catch (e) {
      this.setUser(null);
      this.setAuthenticated(false);
    }

    this.setAuthenticating(false);
    return null;
  }

  @action async authenticate() {
    if (!openPages.includes(window.location.pathname) && this.token) {
      const customer = await api.customer.get({ id: 'me' });

      if (customer) {
        this.setUser(customer);
        this.setAuthenticated(true);
      }
    }

    this.setAuthenticating(false);
    appStore.hideLoading();
  }

  @action reset() { // reset cache
    this.organizations = null;
    this.organization = null;
  }

  @action setUser(customer) {
    if (this.customer && this.customer.id !== customer.id) this.reset();

    this.customer = customer;
  }

  @action setAuthenticating(authenticating) {
    this.isAuthenticating = authenticating;
  }

  @action setAuthenticated(authenticated) {
    this.isAuthenticating = false;
    this.isAuthenticated = authenticated;

    if (!authenticated) this.token = null;
  }

  @action async wechatLogin(payload) {
    appStore.showLoading();

    try {
      const result = await api.auth.wechat(payload);

      if (result.accessToken) {
        this.setToken(result.accessToken);

        const customer = await api.customer.get({ id: 'me' });

        this.setUser(customer);
        this.setAuthenticated(true);
        return customer;
      }

      if (result.wechat) this.setWechat(result.wechat);

      return null;
    } finally {
      appStore.hideLoading();
    }
  }

  bindWechat(payload) {
    if (!this.wechat) return null;

    return api.auth.bind({ token: this.wechat.token, ...payload });
  }

  @action async login(payload) {
    appStore.showLoading();

    try {
      const result = await api.auth.login(payload);

      this.setToken(result.accessToken);

      const customer = await api.customer.get({ id: 'me' });

      this.setUser(customer);
      this.setAuthenticated(true);
      return customer;
    } finally {
      appStore.hideLoading();
    }
  }

  @action logout() {
    this.token = null;
    this.setWechat(null);
  }
}

export default new AuthStore();
