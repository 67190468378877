/* global window */
import axios from 'axios';
import { responseWrapper } from 'rest-utils';
import { CORS } from '../config';
import { log } from './utils';

export default function request(url, options) {
  const defaultOptions = { credentials: 'include' };
  const newOptions = { ...defaultOptions, ...options, url };

  if (url.includes('//')) {
    const urlPaths = url.split('//');
    const origin = `${urlPaths[0]}//${urlPaths[1].split('/')[0]}`;

    if (window.location.origin !== origin) {
      if (CORS && CORS.includes(origin)) {
        newOptions.fetchType = 'CORS';
      }
    }
  }

  if (newOptions.body && !newOptions.data) {
    newOptions.data = newOptions.body;
    delete newOptions.body;
  }

  log.info(options, newOptions);

  return axios(newOptions).then(response =>
    // const { statusText, status, headers } = response;
    responseWrapper(
      {
        data: response.data,
        statusCode: response.status,
        headers: response.headers,
      },
      newOptions
    )
  ).catch((error) => {
    console.error(error);
    const { response } = error;
    let msg;
    let statusCode;
    if (response && response instanceof Object) {
      const { data, statusText } = response;
      statusCode = response.status;
      msg = data.message || statusText;
    } else {
      statusCode = 600;
      msg = error.message || 'Network Error';
    }

    if (statusCode === 401) {
      const redirect = `${window.location.pathname}${window.location.search}`;
      window.location.href = `/auth/login?redirect=${redirect}`;
      return null;
    }

    return Promise.reject(new Error(msg));
  });
}
