import { observable, action } from 'mobx';
import auth from './auth';
import services from '../services';
import config from '../config';

class AppStore {
  @observable user
  @observable isAuthenticating = true;
  @observable isAuthenticated = false;
  @observable loading = true;
  constructor() {
    this.isWechat = (() => {
      const matches = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i);
      return matches && matches[0] === 'micromessenger';
    })();
  }

  @action authenticate = async () => {
    if (config.openPages.indexOf(window.location.pathname) === -1 && auth.token) {
      const user = await services.user.get({ id: 'me' });

      if (user) {
        this.setUser(user);
        this.setAuthenticated(true);
      }
    }

    this.setAuthenticating(false);
    this.hideLoading();
  }

  @action setUser(user) {
    this.user = user;
  }

  @action setAuthenticating(authenticating) {
    this.isAuthenticating = authenticating;
  }

  @action setAuthenticated(authenticated) {
    this.isAuthenticated = authenticated;
  }

  @action showLoading() {
    this.loading = true;
  }

  @action hideLoading() {
    this.loading = false;
  }
}

export default new AppStore();
