import { restWrapper, requestWrapper } from 'rest-utils';
import request from './request';
import { cache } from './utils';

function req(options) {
  const newOptions = { ...options };
  const { withCredentials = true } = options;

  const accessToken = cache.get('accessToken');

  newOptions.headers = {
    Accept: 'application/json',
    Scope: 'customer',
    ...newOptions.headers,
  };

  if (accessToken && withCredentials) {
    newOptions.headers.Authorization = `Bearer ${accessToken}`;
  }

  const wrap = requestWrapper(options.url, options.data, options.qs, options.method);

  return request(wrap.url, { ...newOptions, body: wrap.body });
}

export { req as request };

export function fetch(url, data, options = {}) {
  return req({ url, data, ...options });
}

export default (url, defaultOptions) => restWrapper(req, url, defaultOptions);
