import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './components/AsyncComponent';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

const AsyncHome = asyncComponent(() => import('./containers/Home'));
const AsyncLogin = asyncComponent(() => import('./containers/authorize/Login'));
const AsyncLogout = asyncComponent(() => import('./containers/authorize/Logout'));
const AsyncLoginByWechat = asyncComponent(() => import('./containers/authorize/LoginByWechat'));
const AsyncOrganization = asyncComponent(() => import('./containers/Organization'));
const AsyncSignup = asyncComponent(() => import('./containers/Signup'));
const AsyncBind  = asyncComponent(() => import('./containers/organization/Bind'));
const AsyncNotFound = asyncComponent(() => import('./containers/NotFound'));

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={AsyncHome} props={childProps} />
    <UnauthenticatedRoute path="/auth/login" exact component={AsyncLogin} props={childProps} />
    <UnauthenticatedRoute path="/auth/logout" exact component={AsyncLogout} props={childProps} />
    <UnauthenticatedRoute path="/auth/wechat" exact component={AsyncLoginByWechat} props={childProps} />
    <UnauthenticatedRoute path="/auth/signup" exact component={AsyncSignup} props={childProps} />
    <AuthenticatedRoute path="/organizations/:organization/bind" exact component={AsyncBind} props={childProps} />
    <AuthenticatedRoute path="/notes/:id" exact component={AsyncOrganization} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={AsyncNotFound} />
  </Switch>
);
