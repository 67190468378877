import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Routes from './Routes';
import './App.css';

@inject('authStore') @observer
class App extends Component {
  componentDidMount() {
    this.props.authStore.authenticate();
  }

  render() {
    const { isAuthenticated, isAuthenticating } = this.props.authStore;
    const childProps = {
      isAuthenticated,
    };

    if (isAuthenticating) return null;

    return (
      <div className="App container">
        <Routes childProps={childProps} />
      </div>
    );
  }

}

export default withRouter(App);
