import { prefix } from '../config';

export const log = console;

export const cache = {
  get(key) {
    return window.localStorage[prefix + key];
  },
  set(key, value) {
    if (value === null || value === undefined) {
      delete window.localStorage[prefix + key];
    } else {
      window.localStorage[prefix + key] = value;
    }
  },
};
