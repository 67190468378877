import rest, { fetch } from '../utils/rest';

const orgEntry = '/api/organizations/:organization';

export default {
  consts: () => fetch('/api/consts'),
  auth: {
    wechat: data => fetch('/api/authorize/wechat', data),
    sms: data => fetch('/api/authorize/login/sms', data, { method: 'POST' }),
    login: data => fetch('/api/authorize/login', data, { method: 'POST' }),
    bind: (data, options) => fetch('/api/authorize/wechat/bind', data, {
      method: 'POST', ...options,
    }),
  },
  customer: {
    ...rest('/api/customers/:id'),
    organization: rest('/api/customers/:customer/organizations/:id'),
  },
  organization: {
    ...rest('/api/organizations/:id'),
    nearby: () => fetch('/api/organizations/nearby'),
    current: () => fetch('/api/customers/organizations/current'),
    query: () => fetch('/api/customers/organizations'),
    schedules: data => fetch('/api/organizations/:organization/schedules', data),
  },
  doctor: rest(`${orgEntry}/doctors/:id`),
  product: rest(`${orgEntry}/products/:id`),
  patient: rest(`${orgEntry}/patients/:id`),
};
