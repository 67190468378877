module.exports = {
  name: '长健达',
  prefix: 'ourdrs-',
  CORS: [],
  wechat: {
    proxyServer: process.env.REACT_APP_WECHAT_AUTH_SERVER,
    appid: process.env.REACT_APP_WECHAT_APPID,
  },
  openPages: ['/auth/login', '/auth/logout', '/auth/wechat'],
};
